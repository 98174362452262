import { stat } from 'fs';
import { emailPattern } from '../appconfig/Settings';
import moment from 'moment';

// --------------------------------   START LOGIN PAGE VALIDATION --------------------------  //
export const LoginValidate = (inputDetail: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(inputDetail.email) && !window.cb(inputDetail.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: 'Enter Password *' };
    isError = true;
  } else {
    errors = { ...errors, password: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Attorney Login Validate
export const AttorneyLoginValidate = (mobile: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(mobile) && !window.cb(mobile)) {
    errors = { ...errors, mobile: 'Please Enter Mobile Number *' };
    isError = true;
  } else {
    errors = { ...errors, mobile: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Otp Validate
export const OTPValidate = (otp: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(otp) && !window.cb(otp)) {
    errors = { ...errors, otp: 'Please Enter OTP Sent On Your Mobile *' };
    isError = true;
  } else {
    errors = { ...errors, otp: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

//Four Simple Steps Validate
export const ChangePasswordFormValidate = (inputDetail: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Every Input Condition
  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: "Enter New Password *" }
    isError = true;
  }
  else {
    errors = { ...errors, password: "" };
  }

  if (window.cn(inputDetail.confirm_password) && !window.cb(inputDetail.confirm_password)) {
    errors = { ...errors, confirm_password: "Enter Confirm Password *" }
    isError = true;
  } else if (inputDetail.password !== inputDetail.confirm_password) {
    errors = { ...errors, confirm_password: "Confirm Password does not Match to Password*" }
    isError = true;
  } else {
    errors = { ...errors, confirm_password: "" };
  }

  //End Every Input Condition

  errorObj = { errors, isError }

  return errorObj;
}

export const jobRoleValidate = (jobRoleName: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(jobRoleName) && !window.cb(jobRoleName)) {
    errors = { ...errors, name: 'Enter Job Role *' };
    isError = true;
  } else {
    errors = { ...errors, name: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const jobPostValidate = (jobPostData: any, jobDescription: any, taskDescription: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(jobPostData.job_title) && !window.cb(jobPostData.job_title)) {
    errors = { ...errors, job_title: 'Enter Job Title *' };
    isError = true;
  } else {
    errors = { ...errors, job_title: '' };
  }

  if (window.cn(jobDescription) && !window.cb(jobDescription)) {
    errors = { ...errors, job_description: 'Enter Job Description *' };
    isError = true;
  } else {
    errors = { ...errors, job_description: '' };
  }

  if (window.cn(jobPostData.job_role) && !window.cb(jobPostData.job_role)) {
    errors = { ...errors, job_role: 'Select Job Role *' };
    isError = true;
  } else {
    errors = { ...errors, job_role: '' };
  }

  if(localStorage.getItem("user_type") == "admin"){
    if (window.cn(jobPostData.job_company) && !window.cb(jobPostData.job_company)) {
      errors = { ...errors, job_company: 'Select Job Company *' };
      isError = true;
    } else {
      errors = { ...errors, job_company: '' };
    }
  }

  if (window.cn(jobPostData.work_type) && !window.cb(jobPostData.work_type)) {
    errors = { ...errors, work_type: 'Select Work Type *' };
    isError = true;
  } else {
    errors = { ...errors, work_type: '' };
  }

  if (window.cn(jobPostData.job_location) && !window.cb(jobPostData.job_location)) {
    errors = { ...errors, job_location: 'Enter Job Location *' };
    isError = true;
  } else {
    errors = { ...errors, job_location: '' };
  }

  if (window.cn(jobPostData.currency) && !window.cb(jobPostData.currency)) {
    errors = { ...errors, currency: 'Select Currency *' };
    isError = true;
  } else {
    errors = { ...errors, currency: '' };
  }

  // if (window.cn(jobPostData.salary_from) && !window.cb(jobPostData.salary_from)) {
  //   errors = { ...errors, salary_from: 'Enter Salary From *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, salary_from: '' };
  // }

  // if (window.cn(jobPostData.salary_to) && !window.cb(jobPostData.salary_to)) {
  //   errors = { ...errors, salary_to: 'Enter Salary To *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, salary_to: '' };
  // }

  if (window.cn(jobPostData.experience_from) && !window.cb(jobPostData.experience_from)) {
    errors = { ...errors, experience_from: 'Enter Experience From *' };
    isError = true;
  } else {
    errors = { ...errors, experience_from: '' };
  }

  if (window.cn(jobPostData.experience_from) && !window.cb(jobPostData.experience_from)) {
    errors = { ...errors, experience_to: 'Enter Experience To *' };
    isError = true;
  } else {
    errors = { ...errors, experience_to: '' };
  }

  if (jobPostData.give_task != ""){
    if (window.cn(taskDescription) && !window.cb(taskDescription)) {
      errors = { ...errors, task_description: 'Enter Task Description *' };
      isError = true;
    } else {
      errors = { ...errors, task_description: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const clientDetailsValidate = (clientDetails: any, editId: any, companyLogo: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(clientDetails.first_name) && !window.cb(clientDetails.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(clientDetails.last_name) && !window.cb(clientDetails.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10){
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else{
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (editId === null){
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }
  
  if (window.cn(clientDetails.company_name) && !window.cb(clientDetails.company_name)) {
    errors = { ...errors, company_name: 'Enter Company Name *' };
    isError = true;
  } else {
    errors = { ...errors, company_name: '' };
  }

  if (window.cn(companyLogo) && !window.cb(companyLogo)) {
    errors = { ...errors, company_logo: 'Enter Company Logo *' };
    isError = true;
  } else {
    errors = { ...errors, company_logo: '' };
  }

  errorObj = { errors, isError };

  return errorObj;

};

export const brokerDetailsValidate = (clientDetails: any, editId: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(clientDetails.first_name) && !window.cb(clientDetails.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(clientDetails.last_name) && !window.cb(clientDetails.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (editId === null) {
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;

};

export const teamLeaderDetailsValidate = (clientDetails: any, editId: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(clientDetails.first_name) && !window.cb(clientDetails.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(clientDetails.last_name) && !window.cb(clientDetails.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (editId === null) {
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;

};

export const leadGeneratorsDetailsValidate = (clientDetails: any, editId: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (localStorage.getItem("user_type") == "teamleader" && editId == null){
    if (window.cn(clientDetails.assigned_broker_id) && !window.cb(clientDetails.assigned_broker_id)) {
      errors = { ...errors, assigned_broker_id: 'Please Select Broker *' };
      isError = true;
    } else {
      errors = { ...errors, assigned_broker_id: '' };
    }
  }

  if (window.cn(clientDetails.first_name) && !window.cb(clientDetails.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(clientDetails.last_name) && !window.cb(clientDetails.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (editId === null) {
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;

};

export const dataScrappersDetailsValidate = (clientDetails: any, editId: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (localStorage.getItem("user_type") == "teamleader" && editId == null) {
    if (window.cn(clientDetails.assigned_leadgen_id) && !window.cb(clientDetails.assigned_leadgen_id)) {
      errors = { ...errors, assigned_leadgen_id: 'Please Select Lead Generator *' };
      isError = true;
    } else {
      errors = { ...errors, assigned_leadgen_id: '' };
    }
  }

  if (window.cn(clientDetails.first_name) && !window.cb(clientDetails.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(clientDetails.last_name) && !window.cb(clientDetails.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (editId === null) {
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;

};

export const investorsDetailsValidate = (clientDetails: any, editId: any, NDASign: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(clientDetails.first_name) && !window.cb(clientDetails.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(clientDetails.last_name) && !window.cb(clientDetails.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  if (editId === null) {
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  if (window.cn(clientDetails.login_revoke) && !window.cb(clientDetails.login_revoke)) {
    errors = { ...errors, login_revoke: 'Enter Login Access Days *' };
    isError = true;
  } else {
    errors = { ...errors, login_revoke: '' };
  }

  if(NDASign === true){
    if (window.cn(clientDetails.nda_id) && !window.cb(clientDetails.nda_id) && (clientDetails?.nda_id?.code === undefined)) {
      errors = { ...errors, nda_id: 'Please Select NDA Document *' };
      isError = true;
    } else {
      errors = { ...errors, nda_id: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;

};


export const investmentMaterialValidate = (materialUrl: any, manualFile: any, fileName: any, selectedFolder: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (materialUrl == ""){
    if (window.cn(manualFile) && !window.cb(manualFile)) {
      errors = { ...errors, manual_file: 'Upload File *' };
      isError = true;
    } else {
      errors = { ...errors, manual_file: '' };
    }
  }

  if (window.cn(fileName) && !window.cb(fileName)) {
    errors = { ...errors, file_name: 'Enter File Name *' };
    isError = true;
  } else {
    errors = { ...errors, file_name: '' };
  }

  if (Object.keys(selectedFolder).length == 0) {
    errors = { ...errors, folder: 'Select Folder *' };
    isError = true;
  } else {
    errors = { ...errors, folder: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const investmentMaterialFolderNameValidate = (folderName: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(folderName) && !window.cb(folderName)) {
    errors = { ...errors, folder_name: 'Enter Folder Name *' };
    isError = true;
  } else {
    errors = { ...errors, folder_name: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Profile image validate
export const profileImageValidate = (profile_img: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(profile_img) && !window.cb(profile_img)) {
    errors = { ...errors, profile_img: 'Enter Profile Photo *' };
    isError = true;
  } else {
    errors = { ...errors, profile_img: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add broker to team validate
export const addBrokerToTeamValidate = (selectedBroker: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(selectedBroker) && !window.cb(selectedBroker)) {
    errors = { ...errors, broker: 'Please Select Broker *' };
    isError = true;
  } else {
    errors = { ...errors, broker: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add lead generator to team validate
export const addLeadGenToTeamValidate = (selectedLeadGen: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(selectedLeadGen) && !window.cb(selectedLeadGen)) {
    errors = { ...errors, leadgen: 'Please Select Lead Generator *' };
    isError = true;
  } else {
    errors = { ...errors, leadgen: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add data scraper to team validate
export const addDataScrapperToTeamValidate = (selectedDataScrapper: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(selectedDataScrapper) && !window.cb(selectedDataScrapper)) {
    errors = { ...errors, datascrapper: 'Please Select Data Scrapper *' };
    isError = true;
  } else {
    errors = { ...errors, datascrapper: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add leads validate
export const addLeadsValidate = (leadData: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if(localStorage.getItem("user_type") == "datascrapper"){
    if (window.cn(leadData?.leadgen_id) && !window.cb(leadData?.leadgen_id)) {
      errors = { ...errors, leadgen_id: 'Please Select Lead Generator *' };
      isError = true;
    } else {
      errors = { ...errors, leadgen_id: '' };
    }

    if (window.cn(leadData?.broker_id) && !window.cb(leadData?.broker_id)) {
      errors = { ...errors, broker_id: 'Please Select Broker *' };
      isError = true;
    } else {
      errors = { ...errors, broker_id: '' };
    }
  }

  if (window.cn(leadData?.CE_Engagement_Status) && !window.cb(leadData?.CE_Engagement_Status)) {
    errors = { ...errors, CE_Engagement_Status: 'Please Select CE Engagement Status *' };
    isError = true;
  } else {
    errors = { ...errors, CE_Engagement_Status: '' };
  }

  if (window.cn(leadData?.List_Name) && !window.cb(leadData?.List_Name)) {
    errors = { ...errors, List_Name: 'Enter List Name *' };
    isError = true;
  } else {
    errors = { ...errors, List_Name: '' };
  }

  if (window.cn(leadData?.First_Name) && !window.cb(leadData?.First_Name)) {
    errors = { ...errors, First_Name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, First_Name: '' };
  }

  if (window.cn(leadData?.Last_Name) && !window.cb(leadData?.Last_Name)) {
    errors = { ...errors, Last_Name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, Last_Name: '' };
  }

  if (window.cn(leadData?.Company) && !window.cb(leadData?.Company)) {
    errors = { ...errors, Company: 'Enter Company Name *' };
    isError = true;
  } else {
    errors = { ...errors, Company: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add leads excel validate
export const addLeadsExcelValidate = (leadData: any, leadFile: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (localStorage.getItem("user_type") == "datascrapper") {
    if (window.cn(leadData?.leadgen_id) && !window.cb(leadData?.leadgen_id)) {
      errors = { ...errors, leadgen_id_modal: 'Please Select Lead Generator *' };
      isError = true;
    } else {
      errors = { ...errors, leadgen_id_modal: '' };
    }

    if (window.cn(leadData?.broker_id) && !window.cb(leadData?.broker_id)) {
      errors = { ...errors, broker_id_modal: 'Please Select Broker *' };
      isError = true;
    } else {
      errors = { ...errors, broker_id_modal: '' };
    }
  }

  if (window.cn(leadFile) && !window.cb(leadFile)) {
    errors = { ...errors, file: 'Please Select Or Upload File *' };
    isError = true;
  } else {
    errors = { ...errors, file: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add task validate
export const addTaskValidate = (taskDetail: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(taskDetail) && !window.cb(taskDetail)) {
    errors = { ...errors, task_detail: 'Please Enter Task Detail *' };
    isError = true;
  } else {
    errors = { ...errors, task_detail: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add sms campaign validate
export const addSMSCampaignValidate = (campaignDetail: any, selectedGroup: any, selectedSubGroup: any, extraChecked: any, extraArray: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(campaignDetail?.campaign_name) && !window.cb(campaignDetail?.campaign_name)) {
    errors = { ...errors, campaign_name: 'Please enter campaign name *' };
    isError = true;
  } else {
    errors = { ...errors, campaign_name: '' };
  }

  if (window.cn(campaignDetail?.twilio_number_id) && !window.cb(campaignDetail?.twilio_number_id)) {
    errors = { ...errors, twilio_number_id: 'Please select mobile number to send message from *' };
    isError = true;
  } else {
    errors = { ...errors, twilio_number_id: '' };
  }

  if (window.cn(campaignDetail?.campaign_description) && !window.cb(campaignDetail?.campaign_description)) {
    errors = { ...errors, campaign_description: 'Please enter campaign description *' };
    isError = true;
  } else {
    errors = { ...errors, campaign_description: '' };
  }

  if (window.cn(campaignDetail?.message_flow) && !window.cb(campaignDetail?.message_flow)) {
    errors = { ...errors, message_flow: 'Please enter message flow *' };
    isError = true;
  } else {
    errors = { ...errors, message_flow: '' };
  }

  if (window.cn(campaignDetail?.message_body_1) && !window.cb(campaignDetail?.message_body_1)) {
    errors = { ...errors, message_body_1: 'Please enter message 1 *' };
    isError = true;
  } else {
    errors = { ...errors, message_body_1: '' };
  }

  if (window.cn(campaignDetail?.message_body_2) && !window.cb(campaignDetail?.message_body_2)) {
    errors = { ...errors, message_body_2: 'Please enter message 1 *' };
    isError = true;
  } else {
    errors = { ...errors, message_body_2: '' };
  }

  if (window.cn(campaignDetail?.opt_out_message) && !window.cb(campaignDetail?.opt_out_message)) {
    errors = { ...errors, opt_out_message: 'Please enter opt-out message *' };
    isError = true;
  } else {
    errors = { ...errors, opt_out_message: '' };
  }

  if (window.cn(campaignDetail?.welcome_message) && !window.cb(campaignDetail?.welcome_message)) {
    errors = { ...errors, welcome_message: 'Please enter welcome message *' };
    isError = true;
  } else {
    errors = { ...errors, welcome_message: '' };
  }

  if (selectedGroup === null || selectedGroup.length === 0){
    errors = { ...errors, selected_group: 'Please select at least one group to send message to *' };
    isError = true;
  } else {
    errors = { ...errors, selected_group: '' };
  }

  if (selectedGroup !== null && selectedGroup.length > 0){
    if (selectedGroup.some((item: any) => Object.values(item).includes("Subscribers"))) {
      if (selectedSubGroup === null || selectedSubGroup.length === 0) {
        errors = { ...errors, selected_subgroup: 'Please select at least one sub group to send message to *' };
        isError = true;
      } else {
        errors = { ...errors, selected_subgroup: '' };
      }
    }
  }

  if(extraChecked){
    extraArray.every((o: any) => {
      if (window.cn(o.country) && !window.cb(o.country)) {
        errors = { ...errors, country: 'Please Select Country *' };
        isError = true;
      } else {
        errors = { ...errors, country: '' };
      }

      if (window.cn(o.first_name) && !window.cb(o.first_name)) {
        errors = { ...errors, first_name: 'Please Enter First Name *' };
        isError = true;
      } else {
        errors = { ...errors, first_name: '' };
      }

      if (window.cn(o.last_name) && !window.cb(o.last_name)) {
        errors = { ...errors, last_name: 'Please Enter Last Name *' };
        isError = true;
      } else {
        errors = { ...errors, last_name: '' };
      }

      if (window.cn(o.phone_number) && !window.cb(o.phone_number)) {
        errors = { ...errors, phone_number: 'Please Enter Mobile No. *' };
        isError = true;
      } else {
        errors = { ...errors, phone_number: '' };
      }
    });
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add new recipients validate
export const addNewRecipientsValidate = (extraArray: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  extraArray.every((o: any) => {
    if (window.cn(o.country) && !window.cb(o.country)) {
      errors = { ...errors, country: 'Please Select Country *' };
      isError = true;
    } else {
      errors = { ...errors, country: '' };
    }

    if (window.cn(o.first_name) && !window.cb(o.first_name)) {
      errors = { ...errors, first_name: 'Please Enter First Name *' };
      isError = true;
    } else {
      errors = { ...errors, first_name: '' };
    }

    if (window.cn(o.last_name) && !window.cb(o.last_name)) {
      errors = { ...errors, last_name: 'Please Enter Last Name *' };
      isError = true;
    } else {
      errors = { ...errors, last_name: '' };
    }

    if (window.cn(o.phone_number) && !window.cb(o.phone_number)) {
      errors = { ...errors, phone_number: 'Please Enter Mobile No. *' };
      isError = true;
    } else {
      errors = { ...errors, phone_number: '' };
    }
  });

  errorObj = { errors, isError };

  return errorObj;
};

// Add new message validate
export const addNewMessageValidate = (newMessage: any, startDateTime: any, selectedTimezone: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(newMessage) && !window.cb(newMessage)) {
    errors = { ...errors, message: 'Please Enter Message *' };
    isError = true;
  } else {
    errors = { ...errors, message: '' };
  }

  if (window.cn(selectedTimezone) && !window.cb(selectedTimezone)) {
    errors = { ...errors, timezone: 'Please Select Timezone *' };
    isError = true;
  } else {
    errors = { ...errors, timezone: '' };
  }

  if (window.cn(startDateTime) && !window.cb(startDateTime)) {
    errors = { ...errors, start_datetime: 'Please Select Start Date & Time *' };
    isError = true;
  } else {
    errors = { ...errors, start_datetime: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Role validate
export const roleValidate = (roleName: any, permissionList: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(roleName) && !window.cb(roleName)) {
    errors = { ...errors, role: 'Enter Role Name *' };
    isError = true;
  } else {
    errors = { ...errors, role: '' };
  }

  if (permissionList.length == 0) {
    errors = { ...errors, permissions: 'Please Select At Least One Menu For Access *' };
    isError = true;
  } else {
    errors = { ...errors, permissions: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add campaign start date time
export const addCampaignStartDateTimeValidate = (startDateTime: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(startDateTime) && !window.cb(startDateTime)) {
    errors = { ...errors, start_datetime: 'Please select date & time to run campaign *' };
    isError = true;
  } else {
    errors = { ...errors, start_datetime: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Lead note validate
export const leadNoteValidate = (noteTitle: any, note: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(noteTitle) && !window.cb(noteTitle)) {
    errors = { ...errors, note_title: 'Please Enter Note Title *' };
    isError = true;
  } else {
    errors = { ...errors, note_title: '' };
  }

  if (window.cn(note) && !window.cb(note)) {
    errors = { ...errors, note: 'Please Enter Note *' };
    isError = true;
  } else {
    errors = { ...errors, note: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// NDA validate
export const NDAValidate = (NDAName: any, NDAFile: any, driveFolderURL: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(driveFolderURL) && !window.cb(driveFolderURL)) {
    errors = { ...errors, drive_folder_url: 'Please Enter Drive Folder URL *' };
    isError = true;
  } else {
    errors = { ...errors, drive_folder_url: '' };
  }

  if (window.cn(NDAName) && !window.cb(NDAName)) {
    errors = { ...errors, name: 'Please Enter NDA Name *' };
    isError = true;
  } else {
    errors = { ...errors, name: '' };
  }

  if (window.cn(NDAFile) && !window.cb(NDAFile)) {
    errors = { ...errors, file_url: 'Please Upload NDA Document *' };
    isError = true;
  } else {
    errors = { ...errors, file_url: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Employee validate
export const employeeDetailsValidate = (employeeData: any, editId: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(employeeData.first_name) && !window.cb(employeeData.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(employeeData.last_name) && !window.cb(employeeData.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(employeeData.mobile) && !window.cb(employeeData.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (employeeData.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(employeeData.country) && !window.cb(employeeData.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  if (window.cn(employeeData.email) && !window.cb(employeeData.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (editId === null) {
    if (window.cn(employeeData.password) && !window.cb(employeeData.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;

};

// Leave type validate
export const leaveTypeValidate = (leaveTypeData: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(leaveTypeData.name) && !window.cb(leaveTypeData.name)) {
    errors = { ...errors, name: 'Please Enter Name *' };
    isError = true;
  } else {
    errors = { ...errors, name: '' };
  }

  if (window.cn(leaveTypeData.type) && !window.cb(leaveTypeData.type)) {
    errors = { ...errors, type: 'Please Select Type *' };
    isError = true;
  } else {
    errors = { ...errors, type: '' };
  }

  if (window.cn(leaveTypeData.total_leave_count) && !window.cb(leaveTypeData.total_leave_count)) {
    errors = { ...errors, total_leave_count: 'Please Enter Leaves Per Year *' };
    isError = true;
  } else {
    errors = { ...errors, total_leave_count: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Apply leave validate
export const applyLeaveValidate = (leaveData: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(leaveData.leave_type_id) && !window.cb(leaveData.leave_type_id)) {
    errors = { ...errors, leave_type_id: 'Please Select Leave Type *' };
    isError = true;
  } else {
    errors = { ...errors, leave_type_id: '' };
  }

  if (window.cn(leaveData.title) && !window.cb(leaveData.title)) {
    errors = { ...errors, title: 'Please Enter Title *' };
    isError = true;
  } else {
    errors = { ...errors, title: '' };
  }

  if (window.cn(leaveData.description) && !window.cb(leaveData.description)) {
    errors = { ...errors, description: 'Please Enter Description *' };
    isError = true;
  } else {
    errors = { ...errors, description: '' };
  }

  if (window.cn(leaveData.from_date) && !window.cb(leaveData.from_date)) {
    errors = { ...errors, from_date: 'Please Select From Date *' };
    isError = true;
  } else {
    errors = { ...errors, from_date: '' };
  }

  if (window.cn(leaveData.to_date) && !window.cb(leaveData.to_date)) {
    errors = { ...errors, to_date: 'Please Select To Date *' };
    isError = true;
  } else {
    errors = { ...errors, to_date: '' };
  }

  // Check if from date is less than to date
  if ((leaveData.from_date !== undefined || leaveData.from_date !== "") && (leaveData.to_date !== undefined || leaveData.to_date !== "")){
    if (!moment(leaveData.from_date).isSame(moment(leaveData.to_date)) && !moment(leaveData.from_date).isBefore(moment(leaveData.to_date))){
      errors = { ...errors, date_validate: 'From Date Should Be Date Before To Date *' };
      isError = true;
    }else{
      errors = { ...errors, date_validate: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const convertToInvestorValidate = (convertingDocsData: any, convertingFile: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(convertingDocsData.name) && !window.cb(convertingDocsData.name)) {
    errors = { ...errors, name: 'Please Enter Document Name *' };
    isError = true;
  } else {
    errors = { ...errors, name: '' };
  }

  if (window.cn(convertingDocsData.type) && !window.cb(convertingDocsData.type)) {
    errors = { ...errors, type: 'Please Select Document Type *' };
    isError = true;
  } else {
    errors = { ...errors, type: '' };
  }

  if(convertingDocsData?.type?.code === "eSign"){
    if (window.cn(convertingFile) && !window.cb(convertingFile)) {
      errors = { ...errors, doc_url: 'Please Select Document For eSign *' };
      isError = true;
    } else {
      errors = { ...errors, doc_url: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Convert to investor docs validate
export const convertToInvestorDocsValidate = (selectedDocs: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (selectedDocs.length === 0) {
    errors = { ...errors, converting_docs: 'Please Select Document To Upload by Investor *' };
    isError = true;
  } else {
    errors = { ...errors, converting_docs: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Upload investor docs validate
export const uploadInvestorDocsValidate = (uploadedFile: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(uploadedFile) && !window.cb(uploadedFile)) {
    errors = { ...errors, doc_file: 'Please Select Document To Upload *' };
    isError = true;
  } else {
    errors = { ...errors, doc_file: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Request for doc validate
export const requestForDocValidate = (request: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(request) && !window.cb(request)) {
    errors = { ...errors, request: 'Please Request *' };
    isError = true;
  } else {
    errors = { ...errors, request: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Add investor attorney validate
export const addInvestorAttorneyValidate = (investorAttorneyData: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(investorAttorneyData.full_name) && !window.cb(investorAttorneyData.full_name)) {
    errors = { ...errors, full_name: 'Please Enter Full Name *' };
    isError = true;
  } else {
    errors = { ...errors, full_name: '' };
  }

  if (window.cn(investorAttorneyData.mobile) && !window.cb(investorAttorneyData.mobile)) {
    errors = { ...errors, mobile: 'Please Enter Mobile Number *' };
    isError = true;
  } else {
    if (investorAttorneyData.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(investorAttorneyData.country) && !window.cb(investorAttorneyData.country)) {
    errors = { ...errors, country: 'Please Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// Department validate
export const departmentValidate = (name: any, permissionList: any) => {
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  if (window.cn(name) && !window.cb(name)) {
    errors = { ...errors, name: 'Please Enter Department Name *' };
    isError = true;
  } else {
    errors = { ...errors, name: '' };
  }

  if (permissionList.length == 0) {
    errors = { ...errors, permissions: 'Please Select At Least One Menu For Access *' };
    isError = true;
  } else {
    errors = { ...errors, permissions: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};